
#page.mode-edit {
  background-color: $post-mode-edit;
}

#page.mode-tag-script {
  background-color: $post-mode-tag-script;
}

#page.mode-add-fav {
  background-color: $post-mode-add-fav;
}

#page.mode-remove-fav {
  background-color: $post-mode-remove-fav;
}

#page.mode-vote-up {
  background-color: $post-mode-vote-up;
}

#page.mode-vote-down {
  background-color: $post-mode-vote-down;
}

#page.mode-lock-rating {
  background-color: $post-mode-lock-rating;
}

#page.mode-lock-note {
  background-color: $post-mode-lock-note;
}

#page.mode-approve {
  background-color: $post-mode-approve;
}

#page.mode-notes {
  background-color: $post-mode-notes;
}

#page.mode-delete {
  background-color: $post-mode-delete;
}
