div#c-uploads {
  div#a-new {
    .toggle-button {
      box-sizing: border-box;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: center;
      user-select: none;
      padding: .175em .5rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      margin: 0.25rem 0.5rem 0.25rem 0;
    }
    .toggle-button:last-child { margin-right: 0; }

    .toggle-button.active {
      background-color: themed("color-button-active");
    }

    .rating-e.active {
      background-color: themed("color-rating-explicit");
    }
    .rating-q.active {
      background-color: themed("color-rating-questionable");
    }
    .rating-s.active {
      background-color: themed("color-rating-safe");
    }

    .flex-grid-outer {
      display: flex;
      padding: 10px 0;
    }

    .upload_preview_container.in-editor {
      display: none;
    }

    .upload_preview_container.in-sidebar {
      position: sticky;
      top: 20px;
    }

    .upload_preview_img {
      max-width: 100%;
    }

    .flex-grid {
      display: flex;
      padding: 10px 0;
    }

    .flex-wrap {
      display: flex;
      flex-wrap: wrap;
    }

    .col {
      flex: 1 0 0;
      margin-right: 5px;
    }

    .col2 {
      flex: 2 1 0;
    }

    .border-bottom {
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }

    .section-label {
      white-space: normal;
      font-weight: bold;
    }

    .come-together-now {
      padding-bottom: 0;
    }

    .over-me {
      padding-top: 0;
    }

    .tag-textarea {
      display: inline-block; /* Why were we even unsetting this? It breaks EVERYTHING. */
      font-size: 1rem;
      width: 100%;
      resize: vertical;
    }

    /* Need to override this so it shows up at all. */
    #whitelist-warning {
      display: block;
      float: none;
    }

    .upload-source-row {
      button {
        width: 1.5em;
      }

      input {
        margin-bottom: 2px;
      }
    }

    @media only screen and (orientation: portrait), (max-width: 1100px) {
      #preview-sidebar {
        display: none;
      }

      .upload_preview_container.in-editor {
        display: flex;
        flex-direction: column-reverse;
      }

      .upload_preview_container.in-sidebar {
        display: none;
      }

      .upload_preview_dims {
        text-align: center;
      }

      .upload_preview_img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-height: 500px;
      }

      .below-upload > .upload_preview_img {
        max-height: 150px;
      }

      .related-section {
        flex: 0 1 50%;
      }

      .flex-grid {
        flex-direction: column;
      }

      input {
        max-width: 90%;
      }
    }
  }
  div#a-index {
    .post-info {
      margin-right: 1.5em;
    }
  }

  div.upload-preview {
    display: inline-block;

    > a {
      width: 154px;
      height: 154px;
      margin: 0 10px 10px 0;
      display: inline-block;

      img {
        margin: auto;
        border: 2px solid transparent;

        max-width: 154px;
        max-height: 154px;
      }
    }

    .caption-top {
      font-size: 0.8em;
      margin-bottom: 0;
    }
  }
}
