

#blacklist-box {
  display: none;
  margin-bottom: 1em;

  .blacklist-help {
    font-size: 0.85em;
    font-weight: bold;
  }

  .blacklist-header {
    display: flex;
    justify-content: space-between;
  }
  #blacklist-collapse {
    cursor: pointer;
    &::before {
      content: '▼ '
    }
    &.hidden::before {
      content: '► ';
    }
  }

  #blacklist-list {
    a {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: bottom;
    }

    a.entry-disabled {
      text-decoration: line-through;
    }
  }

  &.sidebar-blacklist ul li {
    list-style-type: disc;
    list-style-position: inside;

    a {
      max-width: 75%;
    }
  }

  &.inline-blacklist {
    margin-bottom: 1em;

    #blacklist-list {
      display: inline;

      li {
        display: inline;
        margin-right: 1em;

        a {
          max-width: 25%;
        }
      }
    }
  }
}

// Completely hide the blacklisted posts on these pages only
#c-posts #a-index, #c-popular, #c-favorites {
  .post-preview.blacklisted {
    display: none !important;
  }
}

#image-container.blacklisted, .post-thumbnail.blacklisted, .post-preview.blacklisted {
  img, video {
    height: 0px !important;
    width: 0px !important;
    padding: 150px 150px 0px 0px !important;
    background: url("images/blacklisted-preview.png") !important;

    @media screen and (max-width: 800px) {
      background-size: 100px !important;
      padding: 100px 100px 0px 0px !important;
    }
  }

  #note-container {
    display: none;
  }
}
