// Base formatting constants
$border-radius-half: 3px;
$border-radius-full: 6px;
$base-padding: 1rem;
$padding-050: $base-padding*0.5;
$padding-025: $base-padding*0.25;
$padding-100: $base-padding;
$padding-125: $base-padding*1.25;
$padding-150: $base-padding*1.5;

// Font
$font-size: 85%;
$h1-size: 2em;
$h2-size: 1.5em;
$h3-size: 1.16667em;
$h4-size: 1em;
$dtext_h1_size: 2em;
$dtext_h2_size: 1.8em;
$dtext_h3_size: 1.6em;
$dtext_h4_size: 1.4em;
$dtext_h5_size: 1.2em;
$dtext_h6_size: 1em;
$base_font_family: Verdana, sans-serif;

$box-shadow-size: 2px 2px 5px;

@mixin preview-type-badge($text) {
  background-color: themed("color-section");
  border: 1px solid themed("color-text-white");
  color: themed("color-text");
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0.5rem;
  text-align: center;
  border-radius: $border-radius-full;
  padding: 3px 5px;
  font-size: 60%;
  font-weight: bold;
  content: $text;
}

// Prevent the user from selecting the text
// This is useful for links styled as buttons
@mixin nonselectable {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Chrome, Edge, Opera and Firefox */
}

// SASS converts UTF-8 character codes to their actual characters.
// This occasionally causes issues. See https://github.com/sass/sass/issues/1395
@function unicode($str) {
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"");
}
