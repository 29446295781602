

.user-feedback-positive {
  color: $positive-record-color;
}

.user-feedback-neutral {
  color: $neutral-record-color;
}

.user-feedback-negative {
  color: $negative-record-background;
}

div#c-user-feedbacks, div#c-moderator-dashboards .activity-container {
  .feedback-category-positive {
    background: $positive-record-background;
  }

  .feedback-category-negative {
    background: $negative-record-background;
  }

  .feedback-category-neutral {
    background: $neutral-record-background;
  }

  blockquote {
    padding: 0.5em;
  }
}
