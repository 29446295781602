
.user-greeting-outer {
  padding: $padding-025 0 $padding-025;
  span.user-greeting {
    color: $user-home-greeting-color;
    cursor: help;
  }
}

div#c-users {
  div#a-show {
    .profile-container {
      width: 100%;
    }

    .bottom-section {
      display: flex;
    }

    .stats-section {
      display: flex;
    }

    .posts-section {
      .posts {
        display: flex;
      }
    }

    .profile-avatar {
      margin-right: 2em;
    }

    .about-section {
      flex-grow: 2;
      display: flex;
      .about-piece {
        flex-grow: 1;
        margin: 0 1em;
      }
      div {
        flex-basis: 50%;
      }
    }

    .vertical-section {
      display: flex;
      flex-direction: column;
    }

    .profile-stats {
      width: 100%;
      border-radius: $border-radius-full;
      padding-left: 0.5em;
      background: themed("color-section");

      abbr {
        cursor: help;
      }
    }

    .user-statistics {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .column {
        display: grid;
        grid-template-columns: max-content minmax(150px, 1fr);
        grid-auto-rows: minmax(1.75em, max-content);
        column-gap: 1em;

        > span:nth-child(odd) {
          font-weight: bold;
          text-align: right;
        }
      }
    }
  }

  div#a-edit {
    h1 {
      margin: 0.5em 0;
    }

    h2 {
      margin: 0.5em 0;
    }

    div.input {
      margin-bottom: 2em;
    }

    div.input span.hint {
      display: block;
      max-width: 70%;
    }

    .active {
      color: themed("color-link-active");
    }
  }

  div#a-new {
    max-width: 60em;

    p {
      font-size: 1.2em;
      line-height: 1.4em;
    }
  }
}

@media screen and (max-width: 800px) {
  body.resp {
    div#c-users {
      div#a-show {
        .stats-section {
          flex-direction: column;
        }
        .bottom-section {
          flex-direction: column;
        }
        .vertical-section {
          flex-direction: row;
          flex-wrap: wrap;
        }
        .posts-section {
          order: 2;
          .posts {
            display: block;
          }
        }
        .user-uploads {
          order: 1;
        }
        .about-section {
          flex-direction: column;
        }
        .about-piece {
          margin: 0;
          margin-bottom: $padding-050;
        }
      }
    }
  }
}
