div.comments-for-post {
  div.notices {
    margin: 1em 0;
  }

  div.list-of-comments {
    article.comment {
      border-radius: $border-radius-half;
      margin-bottom: $padding-050;

      &:target {
        background-color: $comment-highlight-background;
      }

      &[data-is-sticky="true"] {
        background: themed("color-section-lighten-10");
      }

      &[data-is-deleted="true"] {
        background: $comment-hidden-background;
      }

      div.content {
        .comment-vote-up-link:hover, .comment-vote-down-link:hover {
          background-color: $comment-vote-background;
          border-radius: $border-radius-half;
        }
      }
    }

    article.comment.below-threshold:not([data-is-sticky="true"]) {
      opacity: 0.3;
    }

    article.comment.below-threshold:hover {
      opacity: 1.0;
    }
  }
}


div#c-comments {
  div#a-index, div#a-show {
    div.header {
      .post-info {
        margin-right: 1.5em;
      }

      strong, time {
        margin-right: 0.3em;
      }

      div.notices {
        margin: 1em 0;
      }

      div.list-of-tags {
        .comment-tag-hide-link::before {
          content: "Hide Tags";
        }
        &.hidden {
          .tag-list {
            display: none;
          }
          .comment-tag-hide-link::before {
            content: "Show Tags";
          }
        }
      }
    }
  }
}

div#c-comments {
  div#a-index {
    .comment-post {
      border-left: 0.25rem solid themed("color-dtext-section");
      background: themed("color-section-lighten-5");
      box-sizing: border-box;
      padding-left: $padding-050;
      padding-bottom: $padding-025;
      margin-bottom: $padding-100;

      .comments {
        margin-left: 2rem;
      }
    }
    .post-container {
      display: flex;
      .post {
        width: 165px;
      }
    }

    div.comments-for-post {
      .comment-post {
        display: flex;
        .comments {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }
}


@media screen and (max-width: 800px) {
  body.resp {
    div#c-comments {
      div#a-index {
        .comment-post {
          .comments {
            margin-left: 1rem;
          }
        }

        .post-container {
          .post-information {
            margin-left: $padding-050;
          }

          .post {
            width: unset;
          }
        }

        div.comments-for-post {
          .comment-post {
            display: block;
            .comments {
              width: unset;
            }
          }
        }
      }
    }
  }
}

form.edit_comment div.input.boolean {
  display: inline-block;

  label {
    font-weight: normal;
    vertical-align: initial;
  }
}
